/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  contactForm: {
    title: 'Ihre Kontaktdaten',
    step: {
      label: 'Kontaktformular',
    },
    formFields: {
      subjects: {
        label: 'Themen von Interesse',
        options: {
          e_mobility: {
            label: 'E-Mobilität',
          },
          heating: {
            label: 'Wärme- / Heiz-Lösungen',
          },
          solar: {
            label: 'Photovoltaik',
          },
        },
      },
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      street: {
        label: 'Straße',
      },
      streetNumber: {
        label: 'Hausnr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      phone: {
        label: 'Telefonnummer',
      },
      email: {
        label: 'E-Mail-Adresse',
      },
      note: {
        label: 'Anmerkungen',
      },
      privacyConfirmation: {
        component: {
          label:
            'Hiermit stimme ich der <a href="https://store.stawag.de/Info/Datenschutz/" target="_blank">Datenschutzerklärung</a> zu.',
        },
      },
    },
    backButton: 'Zurück zur Startseite',
    subtitles: {
      subjects: 'An welchen Themen sind Sie interessiert?',
      aboutPerson: 'Persönliche Informationen',
      address: 'Adresse',
      contactData: 'Kontaktdaten',
    },
    sentSuccessfully:
      'Vielen Dank für Ihr Interesse an unseren Veranstaltungen und Angeboten. Wir freuen uns Sie informieren zu dürfen.',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
