import { Buffer } from 'buffer';
import axios from 'axios';

import { getApiToken } from './api-token';

export interface ProductRequest {
  // subjects: string[];
  city: string;
  comment: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  productId: string;
  productUrl: string;
  salutationDisplayName: 'Herr' | 'Frau' | 'Keine Angabe';
  street: string;
  zipCode: string;
}

export interface ProductRequestData {
  shopHost: string;
  shopApiKey: string;
  shopUseBasicAuth: boolean;
  shopBasicAuth?: string;
  productRequest: ProductRequest;
  s3BucketName?: string;
  s3FileAttachmentPaths?: Array<string>;
  disableCustomerMail?: boolean;
}

export const sendProductRequest = async (
  productRequestData: ProductRequestData,
): Promise<void> => {
  try {
    const apiToken = await getApiToken();

    const base64EncodedProductRequestData = Buffer.from(
      JSON.stringify(productRequestData),
      'utf8',
    ).toString('base64');

    return axios.post(
      `${process.env.VUE_APP_API_URL}/productRequest`,
      {
        data: base64EncodedProductRequestData,
      },
      {
        headers: { 'X-API-Token': apiToken },
      },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error('An error occurred while sending the product request.');
  }
};
