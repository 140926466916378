import component from './ContactForm.vue';
import { getProductRequest } from './helper';
import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormMultipleChoiceValueType,
  FormTextAreaValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormCheckbox,
  buildFormMultipleChoiceField,
  buildFormTextArea,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { StepTypes } from '@/consts/step-types';
import { sendProductRequest } from '@/lib/submit/send-product-request';

export interface ContactFormStepContext extends AssistantStepContext {
  subjects: FormMultipleChoiceValueType;
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  note: FormTextAreaValueType;
  privacyConfirmation: FormCheckboxValueType;
}

const config: StepDefinition = {
  component,
  fields: [
    buildFormMultipleChoiceField('subjects', {
      component: {
        columns: 2,
        options: [
          {
            value: 'e_mobility',
          },
          {
            value: 'heating',
          },
          {
            value: 'solar',
          },
        ],
      },
    }),
    buildFormTextField('firstname', {
      required: true,
    }),
    buildFormTextField('lastname', {
      required: true,
    }),
    buildFormTextField('street'),
    buildFormTextField('streetNumber'),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
      component: {
        type: 'number',
      },
    }),
    buildFormTextField('city'),
    buildFormTextField('phone', {
      validation: [ValidationType.phoneNumber],
      component: {
        type: 'tel',
      },
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormTextArea('note'),
    buildFormCheckbox('privacyConfirmation', {
      component: {
        showLabel: true,
      },
      hideLabel: true,
      required: true,
      outputFormatter: () => ({
        formattedLabel: false,
        formattedValue: false,
      }),
    }),
  ],
  id: StepId.CONTACT_FORM,
  type: StepTypes.DEFAULT,
  submit: async ({ i18n, assistantContext }) => {
    try {
      const productId = process.env.VUE_APP_SHOP_PRODUCT_ID;
      const productRequest = getProductRequest(assistantContext, productId);

      await sendProductRequest({
        productRequest,
        shopHost: process.env.VUE_APP_STAWAG_HOST,
        shopApiKey: process.env.VUE_APP_STAWAG_API_KEY,
        shopUseBasicAuth: process.env.VUE_APP_STAWAG_USE_BASIC_AUTH === 'true',
        shopBasicAuth:
          process.env.VUE_APP_STAWAG_USE_BASIC_AUTH === 'true'
            ? process.env.VUE_APP_STAWAG_BASIC_AUTH
            : undefined,
        disableCustomerMail:
          process.env.VUE_APP_SHOP_DISABLE_CUSTOMER_MAIL === 'true',
      });
      return;
    } catch (error) {
      let errorMessage = i18n.t('message.error').toString();
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      // eslint-disable-next-line no-console
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  },
};

export default config;
